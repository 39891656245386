<template>
  <div class="app app-dev v">
    <div class="no-flex padding-20">
      <div class="fs-ex-huge bold">快速登录</div>
      <div class="fc-g gap-1x">选择要进行快速登录的用户，该功能仅提供开发人员使用，请注意安全。</div>
    </div>
    <van-field v-model="keyword" @input="loadUsers" class="no-flex" placeholder="输入关键字查找用户" />
    <div class="flex scroll-able-y">
      <template v-for="u in users">
        <div class="item h c sb" :key="u.username" @click="doLogin(u)">
          <span>{{u.nickName}}</span>
          <van-loading :size="20" color="var(--color-white)" v-if="u.signing" />
        </div>
      </template>
      <van-empty description="未找到用户" v-if="!users || !users.length" />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { encrypt } from "@/utils/rsaEncrypt";

export default {
  data() {
    return {
      keyword: null,
      users: [],
    };
  },
  methods: {
    loadUsers() {
      this.loading = true;
      request({
        method: "get",
        url: "/api/users/mdadmin",
        params: {
          blurry: this.keyword,
          page: 1,
          size: 20,
        },
      })
        .then((res) => {
          const users = res?.content || [];
          users.forEach((u) => {
            u.signing = false;
          });
          this.users = users;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    doLogin(user) {
      const fd = {
        username: user.username,
        password: encrypt("Test4Test"),
        rememberMe: false,
        code: "1234",
        uuid: "1234",
      };
      this.signing = true;
      user.signing = true;
      this.$store
        .dispatch("Login", fd)
        .then(() => {
          this.$router.push({ path: this.redirect || "/" });
        })
        .catch(() => {
          this.signing = false;
          user.signing = false;
        });
    },
  },
  mounted() {
    this.loadUsers();
  },
};
</script>

<style lang="scss">
.app-dev {
  .item {
    padding: 0 20px;
    line-height: 36px;
    border-radius: 18px;
    background-color: var(--background-color-light);
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: var(--selected-background-color-darken);
      color: var(--selected-color-darken);
    }
  }
}
</style>